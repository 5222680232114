/*
 * @Author: your name
 * @Date: 2022-04-11 14:00:24
 * @LastEditTime: 2022-04-25 14:08:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/view/partner/apply/applyList/index.js
 */
import {
  applicationList,
  applicationDel,
  applicationShelves,
  applicationNotShelves,
} from "@lib/apply-list";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      listMan: ["菜单注册", "功能注册", "接入配置"],
    };
  },
  methods: {
    stateFormatter({ examineState, shelves }) {
      return shelves
        ? {
            0: "已下架",
            1: "已上架",
            2: "强制下架",
          }[shelves]
        : {
            1: "待审核",
            2: "审核通过",
            3: "审核未通过",
          }[examineState];
    },
    stateFormatterColor({ examineState, shelves }) {
      return shelves
        ? {
            0: "#A30014",
            1: "#04E01A",
            2: "#A30014",
          }[shelves]
        : {
            1: "#02A7F0",
            2: "审核通过",
            3: "#A30014",
          }[examineState];
    },
    // 新增账户
    addAccount(info, editOrSee) {
      if (info == undefined) {
        this.$router.push({ name: "applyAdd", params: { info: undefined } });
      } else {
        /* this.$confirm("修改应用后需重新审核，确定要修改？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => { */
        this.$router.push({
          name: "applyAdd",
          params: { info: JSON.stringify(info), editOrSee },
        });
        /*  })
          .catch(() => {}); */
      }
    },
    /**
     * 更多按钮
     * @param {Object} val 当前列数据
     * @param {String} e 当前点击按钮
     */
    moreButton(val, e) {
      switch (e) {
        case "上架":
          this.appShelves(val.code);
          break;
        case "下架":
          this.appShelves(val.code, 1);
          break;
        case "菜单注册":
          sessionStorage.setItem(
            "applyMenu",
            JSON.stringify({ code: val.code, fullName: val.fullName })
          );

          this.$router.push({
            name: "applyMenu",
          });
          break;
        case "功能注册":
          sessionStorage.setItem(
            "applyUse",
            JSON.stringify({ code: val.code, fullName: val.fullName })
          );
          this.$router.push({
            name: "applyUse",
          });
          break;
        case "接入配置":
          this.$router.push({
            name: "applyLeader",
            params: { info: JSON.stringify(val) },
          });
          break;
      }
    },
    pageChange(currentPage) {
      sessionStorage.setItem("applyPage", currentPage);
      this.page = currentPage;
      this.getListFuction();
    },
    /**
     * 上架
     * @param {Number | String} appId 应用id
     * @param {Number} appId type 0 上架 1 下架
     */
    async appShelves(appId, type = 0) {
      try {
        let res;
        if (!type) {
          res = await applicationShelves({ appId });
        } else {
          res = await applicationNotShelves({ appId });
        }
        if (!res) return;
        const { status } = res;
        if (status === "success") {
          this.$message.success(`${!type ? "上架" : "下架"}成功！`);
          this.getListFuction();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getListFuction() {
      let { result } = await applicationList({
        pageNumber: this.page,
        pageSize: 10,
      });
      result.list.map((item) => {
        const shelves = item.shelves;
        if (shelves) {
          item.listMan = shelves == 0 ? ["上架"] : shelves == 2 ? [] : ["下架"];
        } else {
          item.listMan = [];
        }
        return item;
      });
      this.tableData = result;
    },
    async addAccountDel(val) {
      this.$confirm("确定删除应用？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          applicationDel({ id: val.id }).then((res) => {
            if (res.status === "success") {
              this.getListFuction();
              this.$message.success("删除成功");
            }
          });
        })
        .catch(() => {});
    },
    jieRu(val) {
      this.$router.push({
        name: "applyLeader",
        params: { info: JSON.stringify(val) },
      });
    },
  },
  mounted() {
    this.getListFuction();
    this.sessionStorageRemove(["applyMenu", "applyMenu"]);
  },
  created() {
    let applyPage = sessionStorage.getItem("applyPage");
    if (applyPage) {
      this.page = Number(applyPage);
    }
  },
  beforeRouteEnter: (to, from, next) => {
    if (
      from.name != "applyAdd" &&
      from.name != "applyLeader" &&
      from.name != null
    ) {
      sessionStorage.removeItem("applyPage");
    }
    next();
  },
};
