/*
 * @Author: your name
 * @Date: 2022-04-12 15:39:42
 * @LastEditTime: 2022-04-12 16:07:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/lib/apply-list.js
 */
import { Post, Get } from "@lib/request";
import { oldBase, Base } from "./config";
// 应用接入

/**
 * 查询应用列表
 */
export const applicationList = (data) =>
  Post(oldBase + "/application/list", data);

/**
 * 删除应用
 */
export const applicationDel = (data) =>
  Post(oldBase + "/application/del", data);

/**
 * 上架应用
 */
export const applicationShelves = (data) =>
  Get(Base + "/application/shelves", data);

/**
 * 下架应用
 */
export const applicationNotShelves = (data) =>
  Get(Base + "/application/notShelves", data);
